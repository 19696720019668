const ItemMenu = ({
  item,
  itemSelected,
  onClickHandler
}: $TSFixMe) => {
  return (
    <div
      key={item.key}
      className='cursor-pointer flex justify-center items-center'
    >
      <span
        onClick={() => onClickHandler(item.key)}
        className={item.key === itemSelected ?
          'font-bold tracking-widest text-xs text-white-ligth' :
          'font-bold text-xs tracking-widest text-gray-strong hover:text-white-ligth'
        }
      >
        {item.title}
      </span>
    </div>
  );
}

export default ItemMenu;