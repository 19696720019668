import React from "react";
import { Stack } from "@mui/material";

//Components
import Image from "next/image";
import SocialNetworks from "../SocialNetworksComponent/SocialNetworks";
import LegalInformation from "../LegalInformationComponent/LegalInformation";
import Links from "./Links";

import styles from "./styles/Footer.module.css";

const Footer = ({
  session
}: $TSFixMe) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer_aboutR3d}>
        <Stack
          gap={{ xs: 4, md: 20 }}
          direction={{ xs: "row", md: "row" }}
          justifyContent={{ xs: "center", md: "center" }}
          alignItems={{ xs: "center", md: "flex-start" }}
          className={styles.footer_aboutR3d_inf}
        >
          <div className={styles.footer_aboutR3d_inf_icons}>
            <Image
              src="/logos/logo_footer.svg"
              alt="logo r3d"
              width={200}
              height={35}
              layout="intrinsic"
              objectFit="contain"
            />
            <p>
              R3D es la primera plataforma para fabricar productos
              bajo demanda.
            </p>
            <div className={styles.footer_aboutR3d_inf_socials}>
              <SocialNetworks />
            </div>
          </div>
          <Links session={session} />
        </Stack>
      </div>
      <div className={styles.footer_aboutR3d_inf_socials_movilDevice}>
        <SocialNetworks />
      </div>
      <LegalInformation />
    </footer>
  );
};

export default Footer;