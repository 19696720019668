
import { useEffect } from "react";

const TidioChat = ({
  session = null,
  classname,
  text
}: $TSFixMe) => {

  useEffect(() => {
    // @ts-expect-error TS(2339): Property 'tidioChatApi' does not exist on type 'Wi... Remove this comment to see the full error message
    if (window.tidioChatApi) {
      // @ts-expect-error TS(2339): Property 'tidioChatApi' does not exist on type 'Wi... Remove this comment to see the full error message
      window.tidioChatApi.on("ready", onTidioChatApiReady);
      if (session && session.user) {
        // @ts-expect-error TS(2339): Property 'tidioIdentify' does not exist on type 'D... Remove this comment to see the full error message
        document.tidioIdentify = {
          distinct_id: session.user.userId, // Unique visitor ID in your system
          email: session.user.email, // visitor email
          name: session.user.firstname, // Visitor name
        };
      }
      else {
        // @ts-expect-error TS(2339): Property 'tidioIdentify' does not exist on type 'D... Remove this comment to see the full error message
        document.tidioIdentify = {
          distinct_id: null,
          email: null,
          name: null
        };
      }
    }
    else {
      document.addEventListener("tidioChat-ready", onTidioChatApiReady);
    }
  }, []);

  const onTidioChatApiReady = () => {
    // @ts-expect-error TS(2339): Property 'tidioChatApi' does not exist on type 'Wi... Remove this comment to see the full error message
    if (window.tidioChatApi) {
      // @ts-expect-error TS(2339): Property 'tidioChatApi' does not exist on type 'Wi... Remove this comment to see the full error message
      window.tidioChatApi.hide();
      // @ts-expect-error TS(2339): Property 'tidioChatApi' does not exist on type 'Wi... Remove this comment to see the full error message
      window.tidioChatApi.on("close", function () {
        // @ts-expect-error TS(2339): Property 'tidioChatApi' does not exist on type 'Wi... Remove this comment to see the full error message
        window.tidioChatApi.hide();
      });
    }
  }

  const openTidioChat = () => {
    // @ts-expect-error TS(2339): Property 'tidioChatApi' does not exist on type 'Wi... Remove this comment to see the full error message
    if (window.tidioChatApi) {
      // @ts-expect-error TS(2339): Property 'tidioChatApi' does not exist on type 'Wi... Remove this comment to see the full error message
      window.tidioChatApi.track("click_on_ayuda");
      
      if (session && session.user) {
        // @ts-expect-error TS(2339): Property 'tidioChatApi' does not exist on type 'Wi... Remove this comment to see the full error message
        window.tidioChatApi.setVisitorData({
          distinct_id: session.user.userId,
          email: session.user.email,
          name: session.user.firstname
        });
      }
      else {
        // @ts-expect-error TS(2339): Property 'tidioChatApi' does not exist on type 'Wi... Remove this comment to see the full error message
        window.tidioChatApi.setVisitorData({
          distinct_id: null,
          name: null,
          email: null
        });
      }

      // @ts-expect-error TS(2339): Property 'tidioChatApi' does not exist on type 'Wi... Remove this comment to see the full error message
      window.tidioChatApi.show();
      // @ts-expect-error TS(2339): Property 'tidioChatApi' does not exist on type 'Wi... Remove this comment to see the full error message
      window.tidioChatApi.open();
    }
  }

  return (
    <>
      <a style={{ cursor: 'pointer' }} className={classname} onClick={openTidioChat}>{text}</a>
    </>
  )
}

export default TidioChat;
