import React from 'react';
import Link from 'next/link'
import styles from './styles/LegalInformation.module.css';

const LegalInformation = () => {
    return (
        <div className={styles.footer_legalInformation}>
            <Link href='/legal/privacity'><a target='_blank'>Aviso de Privacidad</a></Link>
            <span>R3D ® es una marca registrada, todos los derechos reservados {new Date().getFullYear()}</span>
            <Link href='/legal/terms_conditions'><a target='_blank'>Términos y Condiciones</a></Link>
        </div>
    )
}

export default LegalInformation;