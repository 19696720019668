import React from "react";
import Link from "next/link";
import Image from "next/image";
import styles from "./styles/SocialNetworks.module.css";

const socialNetworks = [
  {
    link: "https://www.instagram.com/r3d.com.mx/",
    img: "/instagram.png",
    alt: "instagram icon",
  },
  {
    link: "https://twitter.com/R3DMexico",
    img: "/twitter.png",
    alt: "twitter icon",
  },
  {
    link: "https://www.facebook.com/R3DMexico/?_rdc=1&_rdr",
    img: "/facebook.png",
    alt: "facebook icon",
    size: 20
  },
  {
    link: "https://www.linkedin.com/company/r3d-com-mx",
    img: "/linkedin.png",
    alt: "linkedin icon",
  }
];

//Este componente renderiza los iconos de las redes sociales.
const SocialNetworks = () => {
  return (
    <>
      {socialNetworks.map((sn, index) => {
        return (
          <div className={styles.socialNetworks} key={index}>
            <Link href={sn.link}>
              <a target="_blank">
                <Image
                  src={sn.img}
                  width={25}
                  height={25}
                  alt={sn.alt}
                />
              </a>
            </Link>
          </div>
        );
      })}
    </>
  );
};

export default SocialNetworks;