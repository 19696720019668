import React from "react";
import styles from "./styles/Links.module.css";

//Components
import Link from "next/link";
import TidioChat from "../common/Tidio/TidioChat";

const Links = ({
  session
}: $TSFixMe) => {
  return (
    <>
      <ul className={styles.footer_aboutR3d_inf_list}>
        <li className={styles.footer_aboutR3d_inf_list_header}>Perfiles</li>
        <li>
          <Link href={{ pathname: '/', query: { section: 'mired' }}}>
            <a className={styles.footer_aboutR3d_inf_list_link}>MiR3D</a>
          </Link>
        </li>
        <li>
          <Link href={{ pathname: '/', query: { section: 'manufacturer' }}}>
            <a className={styles.footer_aboutR3d_inf_list_link}>
              Fabricantes
            </a>
          </Link>
        </li>
      </ul>

      <ul className={styles.footer_aboutR3d_inf_list}>
        <li className={styles.footer_aboutR3d_inf_list_header}>
          Sobre R3D
        </li>
        <li>
          <Link href="/team">
            <a className={styles.footer_aboutR3d_inf_list_link}>Nosotros</a>
          </Link>
        </li>
        <li>
          <Link href="/blog">
            <a className={styles.footer_aboutR3d_inf_list_link}>Blog</a>
          </Link>
        </li>
      </ul>

      <ul className={styles.footer_aboutR3d_inf_list}>
        <li className={styles.footer_aboutR3d_inf_list_header}>Atención</li>
        <li>
          <Link href="/contact">
            <a className={styles.footer_aboutR3d_inf_list_link}>Contacto</a>
          </Link>
        </li>
        <li>
          <TidioChat
            session={session}
            classname={styles.footer_aboutR3d_inf_list_link}
            text={"Ayuda"}
          />
        </li>
      </ul>
    </>
  );
};

export default Links;