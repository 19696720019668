import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { ObjectId } from 'bson';
import { useRouter } from "next/router";
import { useProfileProvider } from "../../store/ProfileProvider";
import ItemMenu from "./ItemMenu";

const MenuNavMovil = ({
    session,
    position = 'fixed',
    top = '0'
}: $TSFixMe) => {
    const router = useRouter();
    // @ts-expect-error TS(2339): Property 'store' does not exist on type 'unknown'.
    const { store } = useProfileProvider();
    const [itemSelected, setItemSelected] = useState(null);
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (router.pathname !== '/my_projects/new_project/[id]' && !session) {
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
            setItems([{ key: 'new_project', title: 'Iniciar Proyecto' }]);
        }
        else if (router.pathname !== '/my_projects/new_project/[id]' && store.profile === 'MIR3D' && session) {
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
            setItems([{ key: 'new_project', title: 'Iniciar Proyecto' }, { key: 'my_projects', title: 'Mis Proyectos' }]);
        }
        else if (router.pathname === '/my_projects/new_project/[id]' && store.profile === 'MIR3D' && session) {
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
            setItems([{ key: 'my_projects', title: 'Mis Proyectos' }]);
        }
        else if (session && store.profile === 'FABRICANTE') {
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
            setItems([{ key: 'explore', title: 'Explorar' }, { key: 'orders', title: 'Mis Proyectos' }]);
        }
    }, [store.profile]);

    const onClickStartProject = (newItem: $TSFixMe) => {
        setItemSelected(newItem);
        switch (newItem) {
            case 'new_project':
                // localStorage.setItem('phase', '');
                router.push({ pathname: '/my_projects/new_project/[id]' }, { pathname: `/my_projects/new_project/${new ObjectId().toString()}` });
                break;
            default:
                //Explore, my_projects
                router.push(`/${newItem}`);
                break;
        }
    }

    return (
        <Stack position={'relative'} top={top} zIndex={5} fontFamily={'var(--font-family)'}>
            {
                // @ts-expect-error TS(2769): No overload matches this call.
                <Stack
                    width="100%"
                    direction="row"
                    justifyContent={'space-evenly'}
                    alignItems="center" spacing={{ sm: 2, md: 10, lg: 12 }} height={40}
                    backgroundColor={'var(--black-main)'}
                    display={{ sm: 'auto', md: 'none' }}
                    position={position}
                >
                    {items.map((item) => {
                        // @ts-expect-error TS(2339): Property 'key' does not exist on type 'never'.
                        return <ItemMenu key={item.key} item={item} itemSelected={itemSelected} onClickHandler={onClickStartProject} />
                    })}
                </Stack>
            }
        </Stack>
    );
}

export default MenuNavMovil;